import { RequestExtraConfig } from "@Portal/RequestExtraConfig";
import { CreateCache } from "@Utils/Cache/CreateCache";
import { HTTP_INTERCEPTORS, HttpEventType, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { ExistingProvider, Injectable } from "@angular/core";
import { concat, of, throwError } from "rxjs";
import { catchError, filter, shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CacheInterceptor implements HttpInterceptor {

    private cache = CreateCache();

    resetCache() {

        this.cache.clean();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        const requestParams: HttpParams & { interceptorConfig?: RequestExtraConfig } = request.params;

        const primaryCacheKey = request.url;

        const secondaryCacheKeys = request.method === "POST"
            ? request.body
            : request.urlWithParams;

        if (!requestParams.interceptorConfig?.useCache) {

            return next.handle(request);
        }

        if (this.cache.has(primaryCacheKey, secondaryCacheKeys)) {

            return this.cache.get(primaryCacheKey, secondaryCacheKeys);
        }

        const cacheChain = concat(
            of({ type: HttpEventType.Sent }),
            next.handle(request).pipe(
                filter(i => i.type === HttpEventType.Response),
                catchError(error => {

                    this.cache.remove(primaryCacheKey, secondaryCacheKeys);
                    return throwError(error);
                }),
            ),
        ).pipe(
            shareReplay(2),
        );

        this.cache.set(primaryCacheKey, secondaryCacheKeys, cacheChain);
        return cacheChain;
    }
}

export const CacheInterceptorProvider: ExistingProvider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: CacheInterceptor,
    multi: true,
};
