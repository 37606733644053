import { ToastType } from "./toaster.service";

type ToasterConfigType = {

    limit: number;
    newestOnTop: boolean;
    timeOut: number;
    iconClasses: Record<ToastType, string>;
    defaultIcon: string;
    position: string;
    title: string;
    message: string;
    tap: boolean;
};

export const ToasterConfig: ToasterConfigType = {

    limit: 0,
    newestOnTop: true,
    timeOut: 5000,
    iconClasses: {
        error: "toast-error",
        info: "toast-info",
        success: "toast-success",
        warning: "toast-warning",
    },
    defaultIcon: "toast-info",
    position: "toast-top-right",
    title: "toast-title",
    message: "toast-message",
    tap: true,
};
