import { Subject } from "rxjs";
import { RequestExtraConfig } from "@Portal/RequestExtraConfig";
import { filter, scan, distinctUntilChanged, shareReplay } from "rxjs/operators";

export type TrackerEventName = "RequestStarted"
    | "RequestEnded"
    | "RequestFailed"
    | "RequestCancelled";

type RequestConsumerData = {
    delta: number;
    config: RequestExtraConfig;
    name: TrackerEventName;
};

export class RequestTracker {

    private requestConsumerSubject = new Subject<RequestConsumerData>();

    eventStream = this.requestConsumerSubject.pipe(
        filter(i => !i.config.silent),
        scan(
            (aggr, message) => ({
                config: message.config,
                pendingRequests: aggr.pendingRequests + message.delta,
                name: message.name,
            }),
            { pendingRequests: 0, config: {} as RequestExtraConfig, name: "RequestEnded" as TrackerEventName },
        ),
        distinctUntilChanged((oldValue, newValue) => oldValue.pendingRequests == newValue.pendingRequests),
        shareReplay(1),
    );

    processNewRequest(config: RequestExtraConfig) {

        this.requestConsumerSubject.next({ config, delta: 1, name: "RequestStarted" });
    }

    processSuccessResponse(config: RequestExtraConfig) {

        this.requestConsumerSubject.next({ config, delta: -1, name: "RequestEnded"  });
    }

    processErrorResponse(config: RequestExtraConfig) {

        this.requestConsumerSubject.next({ config, delta: -1, name: "RequestFailed" });
    }

    processRequestCancellation(config: RequestExtraConfig) {

        this.requestConsumerSubject.next({ config, delta: -1, name: "RequestCancelled" });
    }
}

export const RequestTrackerInstance = new RequestTracker();
