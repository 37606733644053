import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RequestParams } from "@Assistant/RequestParams";
import { RequestExtraConfig } from "@Assistant/RequestExtraConfig";
import { ApiConfigurationService } from "@Assistant/api-configuration.service";
import { UiEvent } from "../Log/UiEvent";
import { UiRecord } from "../Log/UiRecord";

@Injectable({ providedIn: "root" })
export class UiLogController {

    constructor(
        private httpClient: HttpClient,
        private apiConfig: ApiConfigurationService,
    ) {}

    readonly LogEventPath = `api/v1/UiLog/WriteAuth`;
    readonly LogStructEventPath = `api/v1/UiLog/WriteStructAuth`;

    LogEvent = (
        events: UiEvent[],
        config?: RequestExtraConfig,
    ) => {

        const requestParams = new RequestParams(config);

        return this.httpClient.post<void>(`${this.apiConfig.rootUrl}api/v1/UiLog/WriteAuth`, events, { params: requestParams.toHttpParams() });
    };

    LogStructEvent = (
        events: UiRecord[],
        config?: RequestExtraConfig,
    ) => {

        const requestParams = new RequestParams(config);

        return this.httpClient.post<void>(`${this.apiConfig.rootUrl}api/v1/UiLog/WriteStructAuth`, events, { params: requestParams.toHttpParams() });
    };
}
