export class Deferred<T = any, E = any> {

    private innerResolve: (result?: T) => void;
    private innerReject: (reason?: E) => void;

    constructor() {

        this.promise = new Promise((res, rej) => {

            this.innerResolve = res;
            this.innerReject = rej;
        });
    }

    promise: Promise<T>;

    resolve(result?: T): void {

        this.innerResolve(result);
    }

    reject(error?: E): void {

        this.innerReject(error);
    }
}
