import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class DropRouteReuseStrategy implements RouteReuseStrategy {

    // CHECK_ON_UPGRADE:
    // first 4 methods are the same as on DefaultRouteReuseStrategy (private class in @angular/router)
    shouldDetach(): boolean { return false; }
    store(): void {}
    shouldAttach(): boolean { return false; }
    retrieve(): DetachedRouteHandle | null { return null; }

    // updated behavior - we want to reload route on every last-child navigation (even when the page is same)
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {

        return future.routeConfig === current.routeConfig
            && (future.routeConfig === null
                || !!future.routeConfig.loadChildren
                || !!future.routeConfig.children);
    }
}
