import { IsObject } from "../Assertions";
import { Copy } from "./Copy";
import { IsNullOrUndefined } from "@Utils/Object/IsNullOrUndefined";
import { IsEmpty } from "@Utils/Object/IsEmpty";
import { IsNotEmpty } from "@Utils/Object/IsNotEmpty";

export function DeleteEmptyFieldsInObject<T extends Record<string, any>>(obj: T): T {

    if (!IsObject(obj)) return obj;

    const record = Copy(obj);

    for (const key in record) {

        if (IsNullOrUndefined(record[key])) {

            delete record[key];
        }
        else if (typeof record[key] == "string") {

            record[key] = record[key].trim();
            if (!record[key]) delete record[key];
        }
        else if (typeof record[key] == "object") {

            if (IsNotEmpty(record[key])) record[key] = DeleteEmptyFieldsInObject(record[key]);
            if (IsEmpty(record[key])) delete record[key];
        }
    }

    return record;
}
