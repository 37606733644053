import { IsEmpty, Options } from "./IsEmpty";

export function IsNotEmpty(
    obj: any,
    options: Options = {
        exclude: null,
        treatEmptyStringAsEmpty: false,
        treatWhiteStringAsEmpty: false,
        treatFalseAsEmpty: false,
    },
): boolean {

    return !IsEmpty(obj, options);
}
