import { Injectable } from "@angular/core";
import { ToastType, Toast, ToasterBackendService } from "./toaster-backend.service";

export { ToastType, Toast } from "./toaster-backend.service";

@Injectable({ providedIn: "root" })
export class ToasterService {

    constructor(
        private toasterBackendService: ToasterBackendService,
    ) {}

    get activeToasts() { return this.toasterBackendService.activeToasts; }

    pop(type: ToastType, title: string, body?: string) {

        this.toasterBackendService.pop(type, title, body);
    }

    popWithPromise(toast: Toast) {

        return this.toasterBackendService.popWithPromise(toast);
    }

    popWithUndo(params: { undo(): void; message: string }) {

        this.toasterBackendService.popWithUndo(params);
    }

    clear(predicate?: (i: Toast) => boolean) {

        this.toasterBackendService.clear(predicate);
    }
}
