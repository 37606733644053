import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ExistingProvider, Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SessionIdInterceptor implements HttpInterceptor {

    private sessionId: string;

    setSessionId(sessionId: string) {

        this.sessionId = sessionId;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.sessionId) return next.handle(request);

        const clone = request.clone({
            headers: request.headers.append("x-ui-session-id", this.sessionId),
        });

        return next.handle(clone);
    }
}

export const SessionIdInterceptorProvider: ExistingProvider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: SessionIdInterceptor,
    multi: true,
};
