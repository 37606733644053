import * as E from "linq";

interface KeyValuePair<T> {
    key: T;
    value: any;
}

/* eslint-disable @typescript-eslint/ban-types */
export function EnumerableFromObject2<T,
    K = T extends object ? keyof T : string>(obj: any): E.IEnumerable<KeyValuePair<K>> {

    const array: KeyValuePair<any>[] = [];

    for (const key in obj) {
        if (!(obj[key] instanceof Function)) {
            array.push({ key, value: obj[key] });
        }
    }

    return E.from(array);
}
