import { Injectable } from "@angular/core";
import { StartupModel } from "@Portal/Site/Models/Shared/StartupModel";
import { WindowRef } from "@Shared2/window-ref.service";
import { BehaviorSubject } from "rxjs";

type NonFlagProperties = "action"
    | "bannerList"
    | "colorScheme"
    | "deployVersion"
    | "dmvdeskImportParameters"
    | "pageInject"
    | "profile"
    | "reciprocityStateList"
    | "solution"
    | "solutionList"
    | "solutionSettings"
    | "supportedMilitaryStateList"
    | "userPreferences";

export type FlagMap = Omit<StartupModel, NonFlagProperties>;

@Injectable()
export class AppConfigService {

    constructor(
        private windowRef: WindowRef,
    ) {}

    private startupModelLoadingStatusSubject = new BehaviorSubject<"INITIAL" | "LOADING" | "LOADED" | "FAILED">("INITIAL");
    protected startupModel: StartupModel;

    get startupModelLoadingStatus$() { return this.startupModelLoadingStatusSubject.asObservable(); }
    get startupModelLoaded() { return this.startupModelLoadingStatusSubject.getValue() === "LOADED"; }
    get startupModelLoading() { return this.startupModelLoadingStatusSubject.getValue() === "LOADING"; }
    startupModelError: string;

    // Profile and app -related parameters
    get bannerList() { return this.startupModel.bannerList; }
    get deployVersion() { return this.startupModel.deployVersion; }
    get profile() { return this.startupModel.profile; }
    get solution() { return this.startupModel.solution; }
    get solutionList() { return this.startupModel.solutionList; }
    get userPreferences() { return this.startupModel.userPreferences; }
    get colorScheme() { return this.startupModel.colorScheme; }

    // Page injects
    get action() { return this.startupModel.action; }
    get pageInject() { return this.startupModel.pageInject; }

    // Solution-specific parameters
    get dmvdeskImportParameters() { return this.startupModel.dmvdeskImportParameters; }
    get reciprocityStateList() { return this.startupModel.reciprocityStateList; }
    get solutionSettings() { return this.startupModel.solutionSettings; }
    get supportedMilitaryStateList() { return this.startupModel.supportedMilitaryStateList; }

    // Static flags
    get flags() { return this.startupModel as FlagMap; }

    getPortalAssistantUrl() {

        const path = "/vitu-portal-assistant-api";
        const { host, origin } = this.windowRef.nativeWindow.location;

        return origin.match(/:\d+$/) && !host.startsWith("localhost")
            ? origin.replace(/:\d+$/, "") + path
            : path;
    }

    async loadStartupModelAsync(rawUrl: string) {

        this.startupModelLoadingStatusSubject.next("LOADING");

        const xhr = new XMLHttpRequest();
        xhr.onload = () => {

            if (xhr.status === 200) {

                this.startupModel = JSON.parse(xhr.response).model;
                this.startupModelLoadingStatusSubject.next("LOADED");
            }
            else if (xhr.status === 401) {

                window.location.href = `/container/login?ReturnUrl=${decodeURIComponent(window.location.pathname + window.location.search)}`;
            }
            else {

                this.startupModelLoadingStatusSubject.next("FAILED");
                this.startupModelError = xhr.response || "Failed to initialize the application.";
            }
        };

        // eslint-disable-next-line prefer-template
        const url = rawUrl + (rawUrl.indexOf("?") > -1 ? "&" : "?")
            + "request_timestamp="
            + Date.now();

        xhr.open("GET", url);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.send();
    }
}
