import { EnumerableFromObject2 } from "@Shared2/EnumerableFromObject2";
import { IsNullOrUndefined } from "./IsNullOrUndefined";

export interface Options {
    exclude?: string[];
    treatEmptyStringAsEmpty?: boolean;
    treatWhiteStringAsEmpty?: boolean;
    treatFalseAsEmpty?: boolean;
}

export function IsEmpty(
    obj: any,
    options: Options = {
        exclude: null,
        treatEmptyStringAsEmpty: false,
        treatWhiteStringAsEmpty: false,
        treatFalseAsEmpty: false,
    },
): boolean {

    return EnumerableFromObject2(obj)
        .where(i => !options.exclude || options.exclude.indexOf(i.key) < 0)
        .all(i => {

            if ((options.treatFalseAsEmpty && i.value === false)
                || (options.treatEmptyStringAsEmpty && i.value === "")
                || (options.treatWhiteStringAsEmpty && i.value != null
                    && typeof i.value === "string" && i.value.trim() === "")) {

                return true;
            }

            return IsNullOrUndefined(i.value);
        });
}
