import { OperatorFunction, Observable, timer, NEVER, merge } from "rxjs";
import { bufferCount, bufferWhen, exhaustMap, first } from "rxjs/operators";

export function debouncableBuffer<T>(getInterval: () => number, maxCount: number = Infinity): OperatorFunction<T, T[]> {

    return (source: Observable<T>) => source.pipe(
        bufferWhen(() => {

            const timeoutHandler = source.pipe(exhaustMap(() => timer(getInterval())));
            const maxCountHandler = maxCount === Infinity
                ? NEVER
                : source.pipe(bufferCount(maxCount));

            return merge(timeoutHandler, maxCountHandler).pipe(first());
        }),
    );
}
