import * as E from "linq";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class IdGeneratorService {

    private uniqueNumericId = 0;

    getUniqueNumericId() {

        return ++this.uniqueNumericId;
    }

    getUuid() {

        return [
            this.generateUuidSection(2),
            this.generateUuidSection(1),
            this.generateUuidSection(1),
            this.generateUuidSection(1),
            this.generateUuidSection(3),
        ].join("-");
    }

    private generateUuidSection(count: number) {

        // extracted from 'guid' npm package

        return E.generate(
            () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
            count,
        ).toJoinedString("");
    }
}
