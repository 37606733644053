import { IsObject } from "../Assertions";
import { Copy } from "./Copy";

export function TrimStringsInObject<T extends Record<string, any>>(obj: T): T {

    if (!IsObject(obj)) return obj;

    const record = Copy(obj);

    for (const key in record) {

        if (typeof record[key] == "string") record[key] = record[key].trim();
        else if (typeof record[key] == "object") record[key] = TrimStringsInObject(record[key]);
    }

    return record;
}
