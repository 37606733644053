import { ErrorHandler, Injectable } from "@angular/core";
import { Logger } from "./logger.service";
import { LogLevel } from "@Assistant/SystemTypes/Logging/LogLevel";

@Injectable({ providedIn: "root" })
export class VituExceptionHandlerService implements ErrorHandler {

    constructor(
        private logger: Logger,
    ) {}

    private originalHandler = new ErrorHandler();

    handleError(error: any) {

        this.originalHandler.handleError(error);

        this.logger.log({
            logLevel: LogLevel.Error,
            eventName: "BROWSER_ERROR",
            payload: error instanceof Error
                ? error.toString()
                : error,
        });
    }
}

VituAngularJsExceptionHandlerFactory.$inject = ["$log", "com.mvsc.logger"];
export function VituAngularJsExceptionHandlerFactory(
    $log: { error(...args: any[]): void },
    cloudLogger: Logger,
) {

    return function VituAngularJsExceptionHandler(error, cause) {

        $log.error(error, cause);

        cloudLogger.log({
            logLevel: LogLevel.Error,
            eventName: "BROWSER_ERROR",
            payload: error instanceof Error
                ? `${error.name}: ${error.stack ?? error.message}`
                : error,
        });
    };
}
