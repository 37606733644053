export interface SyncParams {
    locationId: number | string;
    type: "pending" | "sold";
    requestId: string;
    searchField: number | string;
    last5Vin: string;
    importType: "VituInterstate" | "ConvergenceDeal" | "NationalDesking" | null;
    titlingState: string | null;
}

export class Environment {

    constructor(private environment: {
        prod: boolean;
        stage: boolean;
        test: boolean;
        devMode: boolean;
        allowedCloudLogHosts: string[];
        madaHost: string;
        gtagId: string;
    }) {}

    get test() { return this.environment.test; }
    get stage() { return this.environment.stage; }
    get prod() { return this.environment.prod; }
    get gtagId() { return this.environment.gtagId; }
    get devMode() { return this.environment.devMode; }
    get madaHost() { return this.environment.madaHost; }
    get allowedCloudLogHosts() { return this.environment.allowedCloudLogHosts; }

    runElectronImport(params: SyncParams) {

        return new Promise<boolean>((resolve, reject) => {

            if (!this.canRunElectronImport()) {

                resolve(false);
                return;
            }

            const runElectronImport = this.getElectronImportMethod();

            runElectronImport(params).then(

                () => { resolve(true); },
                error => { reject(error); },
            );
        });
    }

    canRunElectronImport() {

        return this.getElectronImportMethod() !== null;
    }

    private getElectronImportMethod(): (params: SyncParams) => Promise<any> {

        if (typeof window["runElectronImport"] == "function") return window["runElectronImport"];
        if (typeof window["runBlueZoneSynchronization"] == "function") return window["runBlueZoneSynchronization"];
        return null;
    }
}
