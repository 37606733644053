import { InjectionToken } from "@angular/core";
import { CodeSolutionName } from "@Portal/Core/Solutions/CodeSolutionName";
import { InternalSolutionCode } from "@Portal/Core/Solutions/InternalSolutionCode";

export enum SolutionCode {
    BILLING = "Billing",
    BLOG = "Blog",
    COMMON = "Common",
    ELT = "Elt",
    FLEET = "Fleet",
    INTERSTATE = "Interstate",
    INSURANCE = "Insurance",
    LOGIN = "Login",
    RETAIL = "Retail",
}

export const SOLUTION = new InjectionToken<SolutionCode>("Solution");

export const SOLUTION_LIST = new InjectionToken<CodeSolutionName[]>("Solution list");

export function toInternalSolutionCode(code: SolutionCode) {

    const map = {
        [SolutionCode.COMMON]: null,
        [SolutionCode.LOGIN]: null,
        [SolutionCode.BILLING]: InternalSolutionCode.BILLING,
        [SolutionCode.BLOG]: InternalSolutionCode.BLOG,
        [SolutionCode.ELT]: InternalSolutionCode.ELT,
        [SolutionCode.FLEET]: InternalSolutionCode.FLEET,
        [SolutionCode.INTERSTATE]: InternalSolutionCode.INTERSTATE,
        [SolutionCode.INSURANCE]: InternalSolutionCode.INSURANCE,
        [SolutionCode.RETAIL]: InternalSolutionCode.RETAIL,
    };

    return map[code];
}
