import { Component, OnInit } from "@angular/core";
import { filter, first } from "rxjs/operators";
import { AppConfigService } from "./app-config.service";

@Component({
    selector: "mvsc-app-root",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <router-outlet (activate)="removeLaunchAnimation()"></router-outlet>
        <div *ngIf="appConfigService.startupModelError"
            class="underConstruction">
            <a href="/" class="underConstruction__logo"></a>
            <div class="underConstruction__wrap">
                <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3203 26.8633C18.0469 26.4987 17.9102 26.1341 17.9102 25.7695C17.9102 25.3138 18.0469 24.9036 18.3203 24.5391L23.7891 19.0703C24.0625 18.7969 24.4271 18.6602 24.8828 18.6602C25.3385 18.6602 25.7487 18.7969 26.1133 19.0703L35 27.957L43.8867 19.0703C44.2513 18.7969 44.6159 18.6602 44.9805 18.6602C45.4362 18.6602 45.8464 18.7969 46.2109 19.0703L51.6797 24.5391C51.9531 24.8125 52.0898 25.1771 52.0898 25.6328C52.0898 26.0885 51.9531 26.4987 51.6797 26.8633L42.793 35.75L51.6797 44.6367C51.9531 45.0013 52.0898 45.4115 52.0898 45.8672C52.0898 46.2318 51.9531 46.5964 51.6797 46.9609L46.2109 52.4297C45.9375 52.7031 45.5729 52.8398 45.1172 52.8398C44.6615 52.8398 44.2513 52.7031 43.8867 52.4297L35 43.543L26.1133 52.4297C25.7487 52.7031 25.3385 52.8398 24.8828 52.8398C24.5182 52.8398 24.1536 52.7031 23.7891 52.4297L18.3203 46.9609C18.0469 46.6875 17.9102 46.3229 17.9102 45.8672C17.9102 45.4115 18.0469 45.0013 18.3203 44.6367L27.3438 35.75L18.3203 26.8633Z" fill="#21BAE7"/>
                    <path opacity="0.4" d="M68.0859 21.2578C69.362 22.625 70 24.1745 70 25.9062V45.5938C70 47.3255 69.362 48.8294 68.0859 50.1055L49.4922 68.8359C48.125 70.112 46.5755 70.75 44.8438 70.75H25.1562C23.4245 70.75 21.9206 70.112 20.6445 68.8359L1.91406 50.2422C0.638021 48.875 0 47.3255 0 45.5938V25.9062C0 24.1745 0.638021 22.6706 1.91406 21.3945L20.5078 2.66406C21.875 1.38802 23.4245 0.75 25.1562 0.75H44.8438C46.5755 0.75 48.0794 1.38802 49.3555 2.66406L68.0859 21.2578ZM51.6797 44.6367L42.793 35.75L51.6797 26.8633C51.9531 26.4987 52.0898 26.0885 52.0898 25.6328C52.0898 25.1771 51.9531 24.8125 51.6797 24.5391L46.2109 19.0703C45.8464 18.7969 45.4362 18.6602 44.9805 18.6602C44.6159 18.6602 44.2513 18.7969 43.8867 19.0703L35 27.957L26.1133 19.0703C25.7487 18.7969 25.3385 18.6602 24.8828 18.6602C24.4271 18.6602 24.0625 18.7969 23.7891 19.0703L18.3203 24.5391C18.0469 24.9036 17.9102 25.3138 17.9102 25.7695C17.9102 26.1341 18.0469 26.4987 18.3203 26.8633L27.3438 35.75L18.3203 44.6367C18.0469 45.0013 17.9102 45.4115 17.9102 45.8672C17.9102 46.3229 18.0469 46.6875 18.3203 46.9609L23.7891 52.4297C24.1536 52.7031 24.5182 52.8398 24.8828 52.8398C25.3385 52.8398 25.7487 52.7031 26.1133 52.4297L35 43.543L43.8867 52.4297C44.2513 52.7031 44.6615 52.8398 45.1172 52.8398C45.5729 52.8398 45.9375 52.7031 46.2109 52.4297L51.6797 46.9609C51.9531 46.5964 52.0898 46.2318 52.0898 45.8672C52.0898 45.4115 51.9531 45.0013 51.6797 44.6367Z" fill="#21BAE7"/>
                </svg>
                <h1>Something went wrong</h1>
                <p>{{ appConfigService.startupModelError }}</p>
                <a onclick="location.reload()" class="btn btn-primary">Reload Page</a>
            </div>
        </div>
    `,
})
export class AppRootComponent implements OnInit {

    constructor(
        public appConfigService: AppConfigService,
    ) {}

    ngOnInit() {

        this.appConfigService.startupModelLoadingStatus$
            .pipe(
                filter(i => i === "FAILED"),
                first(),
            )
            .subscribe(() => {

                this.removeLaunchAnimation();
            });
    }

    removeLaunchAnimation() {

        const launch = document.getElementById("launch-animation");
        if (launch) launch.parentElement.removeChild(launch);
    }
}
