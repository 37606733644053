import { HttpErrorModel } from "@Utils/http-error-model";
import { Observable, throwError, ObservableInput, OperatorFunction, ObservedValueOf } from "rxjs";
import { catchError } from "rxjs/operators";

export function catchHttpError<T, I extends ObservableInput<any>>(
    handler: (error: HttpErrorModel) => I,
): OperatorFunction<T, T | ObservedValueOf<I>> {

    return <T>(source: Observable<T>) => source.pipe(
        catchError(error => {

            return error instanceof HttpErrorModel
                ? handler(error)
                : throwError(error);
        }),
    );
}
