import { DefaultUrlSerializer, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

    parse(url: string): UrlTree {

        const questionSignIndex = url.indexOf("?");

        const preQueryPart = questionSignIndex > -1
            ? url.substring(0, questionSignIndex)
            : url;

        const queryPart = questionSignIndex > -1
            ? url.substring(questionSignIndex)
            : "";

        return super.parse(preQueryPart.toLowerCase() + queryPart);
    }
}
