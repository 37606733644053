import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpHeaders, HttpEvent, HTTP_INTERCEPTORS, HttpEventType, HttpParams } from "@angular/common/http";
import { tap, finalize, catchError, switchMap } from "rxjs/operators";
import { ExistingProvider, Injectable } from "@angular/core";
import { RequestTracker } from "@Shared2/http-interceptors/request-tracker";
import { RequestExtraConfig } from "@Portal/RequestExtraConfig";
import { ToasterService, ToastType } from "@Shared2/toaster/toaster.service";
import { WindowRef } from "@Shared2/window-ref.service";
import { throwError, EMPTY, from, of } from "rxjs";
import { HttpErrorModel } from "@Utils/http-error-model";
import { catchHttpError } from "@Utils/RxJs/catch-http-error";
import { TrimStringsInObject } from "@Utils/Object/trim-strings-in-object";
import { DeleteEmptyFieldsInObject } from "@Utils/Object/delete-empty-fields-in-object";
import { Copy } from "@Utils/Object/Copy";
import { Environment } from "@Infrastructure/Environment";

@Injectable({ providedIn: "root" })
export class AngularHttpInterceptor implements HttpInterceptor {

    constructor(
        private environment: Environment,
        private toaster: ToasterService,
        private windowRef: WindowRef,
        private requestTracker: RequestTracker,
    ) {}

    private transformHttpErrorToJsonOrString(httpError: any): Promise<any> {

        return new Promise(resolve => {

            if (httpError instanceof ArrayBuffer) {

                resolve(String.fromCharCode.apply(null, new Uint8Array(httpError)));
            }
            else if (httpError instanceof Blob) {

                const blobReader = new FileReader();
                blobReader.addEventListener("loadend", () => {

                    try {
                        const parsed = JSON.parse(blobReader.result as string);
                        resolve(parsed);
                    }
                    catch {
                        resolve(blobReader.result);
                    }
                });

                blobReader.readAsText(httpError);
            }
            else {

                resolve(httpError);
            }
        });
    }

    private async transformHttpError(httpErrorEvent: HttpErrorResponse) {

        const errorModel = new HttpErrorModel();
        errorModel.status = httpErrorEvent.status;
        errorModel.corelationId = httpErrorEvent.headers.get("Correlation_id") ?? "";

        const errorBody = await this.transformHttpErrorToJsonOrString(httpErrorEvent.error);

        if (typeof errorBody == "object" && errorBody?.errors && Array.isArray(errorBody.errors)) {

            errorModel.errors = errorBody?.errors;
        }
        else if (typeof errorBody == "string") {

            errorModel.errors = [errorBody];
        }
        else if (httpErrorEvent.status === 0) {

            errorModel.errors = ["Connection interrupted. Please try again"];
        }
        else if (httpErrorEvent.message) {

            errorModel.errors = [httpErrorEvent.message];
        }
        else {

            errorModel.errors = ["Unknown request error"];
        }

        return errorModel;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        const requestParams: HttpParams & { interceptorConfig?: RequestExtraConfig } = request.params;

        const interceptorConfig: RequestExtraConfig = {
            silent: false,
            processingDim: false,
            suppressErrorToast: false,
            trimBodyStrings: true,
            ...(requestParams.interceptorConfig ?? {}),
        };

        let requestSent = false;
        let responseOrErrorProcessed = false;

        const handleAuthError = (errorEvent: any) => {

            if (errorEvent instanceof HttpErrorResponse && errorEvent.status == 401) {

                this.windowRef.nativeWindow.location.reload();
                return EMPTY;
            }

            return throwError(errorEvent);
        };

        const tryConvertToHttpError = (errorEvent: any) => {

            const errorSource = errorEvent instanceof HttpErrorResponse
                ? from(this.transformHttpError(errorEvent))
                : of(errorEvent);

            return errorSource.pipe(switchMap(i => throwError(i)));
        };

        const notifyAboutRequestStartOrSuccess = (event: HttpEvent<any>) => {

            if (event.type == HttpEventType.Response) {

                responseOrErrorProcessed = true;
                this.requestTracker.processSuccessResponse(interceptorConfig);
            }
            if (event.type == HttpEventType.Sent) {

                requestSent = true;
                this.requestTracker.processNewRequest(interceptorConfig);
            }
        };

        const notifyIfRequestWasCancelled = () => {

            if (requestSent && !responseOrErrorProcessed) {

                this.requestTracker.processRequestCancellation(interceptorConfig);
            }
        };

        let body = Copy(request.body);

        if (interceptorConfig.trimBodyStrings) body = TrimStringsInObject(body);
        if (interceptorConfig.deleteEmptyFields) body = DeleteEmptyFieldsInObject(body);

        // VITU-979 - fix IE issue
        const clonedRequest = request.clone({
            headers: new HttpHeaders({
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "x-requested-with": "XMLHttpRequest",
            }),
            body,
        });

        return next.handle(clonedRequest)
            .pipe(
                tap(notifyAboutRequestStartOrSuccess),
                catchError((error: any) => handleAuthError(error)),
                catchError((error: any) => tryConvertToHttpError(error)),
                catchHttpError(errorModel => {

                    responseOrErrorProcessed = true;
                    this.requestTracker.processErrorResponse(interceptorConfig);

                    if (!interceptorConfig.suppressErrorToast) {

                        const errorMessage = `Server error: ${errorModel.errors[0]}`;

                        if (this.environment.test || this.environment.stage) {

                            const env = this.environment.test ? "test" : "stage";
                            const corelationId = errorModel.corelationId;
                            const tracesLink = `https://console.cloud.google.com/traces/list?project=vitu-platform-services-${env}&tid=${corelationId}`;
                            const logsLink = `https://console.cloud.google.com/logs/query;query=trace%3D%22projects%2Fvitu-platform-services-${env}%2Ftraces%2F${corelationId}%22;summaryFields=resource%252Flabels%252Fcontainer_name:false:32:beginning;duration=PT30M?project=vitu-platform-services-${env}`;

                            const body = `
                                <span class="toast-traces">Check <a href="${tracesLink}" target="_blank">Traces</a> or <a href="${logsLink}" target="_blank">Logs</a> for details</span>
                            `;

                            this.toaster.pop(ToastType.ERROR, errorMessage, body);
                        }
                        else {
                            this.toaster.pop(ToastType.ERROR, errorMessage);
                        }
                    }

                    return throwError(errorModel);
                }),
                finalize(notifyIfRequestWasCancelled),
            );
    }
}

export const AngularHttpInterceptorProvider: ExistingProvider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: AngularHttpInterceptor,
    multi: true,
};
